
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: 'see more'
    },
    rows: {
      type: Number,
      default: 5
    }
  },
  setup (props) {
    const captionClasses = ref([`line-clamp-${props.rows}`])
    const targetClasses = ref<string[]>([])

    const seeMore = () => { 
      captionClasses.value = []
      targetClasses.value = ['hidden'] 
    }

    return {
      captionClasses,
      targetClasses,
      seeMore
    }
  }
})
