<template>
  <article
    class="p-4 bg-gray-50 dark:bg-gray-900 flex items-center justify-center pointer-events-none select-none"
    v-if="isValid"
  >
    <div class="py-4 w-full bg-white dark:bg-gray-800 shadow rounded-lg max-w-lg">
      <div class="px-5">
        <div class="flex mb-4">
          <img class="w-12 h-12 rounded-full" :src="profilePick"/>
          <div class="ml-2 mt-0.5">
            <span class="block font-medium text-base leading-snug text-black dark:text-gray-100">{{ profileName }}</span>
            <span class="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
              1 min
              <svg class="inline-block ml-1 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" data-supported-dps="16x16" fill="currentColor" width="16" height="16" focusable="false">
                <path d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z"></path>
              </svg>
            </span>
          </div>
        </div>
        <caption-preview
          class="text-gray-800 text-sm dark:text-gray-100 leading-snug md:leading-normal whitespace-pre-wrap pointer-events-auto"
          v-if="hasContent"
        >
          {{ content }}
        </caption-preview>
      </div>
      <div class="feed-img mt-2">
        <div class="relative">
          <div
            v-if="isLoadingCreative"
            class="absolute flex h-full items-center justify-center w-full bg-gray-500 opacity-50">
            <i class="fas fa-spin fa-spinner fa-2x text-white"></i>
          </div>
          <img
            :src="thumbnail"
            alt=""
            @error="addDefaultSrc"
          >
        </div>
      </div>
      <div class="px-5">
        <div class="flex justify-between items-center mt-5">
          <div class="flex ">
            <svg class="p-0.5 h-6 w-6 rounded-full z-20 bg-white dark:bg-gray-800 " xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'><defs><linearGradient id='a1' x1='50%' x2='50%' y1='0%' y2='100%'><stop offset='0%' stop-color='#18AFFF'/><stop offset='100%' stop-color='#0062DF'/></linearGradient><filter id='c1' width='118.8%' height='118.8%' x='-9.4%' y='-9.4%' filterUnits='objectBoundingBox'><feGaussianBlur in='SourceAlpha' result='shadowBlurInner1' stdDeviation='1'/><feOffset dy='-1' in='shadowBlurInner1' result='shadowOffsetInner1'/><feComposite in='shadowOffsetInner1' in2='SourceAlpha' k2='-1' k3='1' operator='arithmetic' result='shadowInnerInner1'/><feColorMatrix in='shadowInnerInner1' values='0 0 0 0 0 0 0 0 0 0.299356041 0 0 0 0 0.681187726 0 0 0 0.3495684 0'/></filter><path id='b1' d='M8 0a8 8 0 00-8 8 8 8 0 1016 0 8 8 0 00-8-8z'/></defs><g fill='none'><use fill='url(#a1)' xlink:href='#b1'/><use fill='black' filter='url(#c1)' xlink:href='#b1'/><path fill='white' d='M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725a.73.73 0 01.089.546c-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666zM3.6 7h.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6z'/></g></svg>
            <span class="ml-1 text-gray-500 dark:text-gray-400  font-light">8</span>
          </div>  
          <div class="ml-1 text-gray-500 dark:text-gray-400 font-light">33 comments</div>
        </div>
      </div>
    </div>
  </article>
  <div
    class="p-4"
    v-else
  >
    <p class="font-semibold mb-6">
      {{ $t('page.content.preview.sentence.incompatible_with', { source: 'Facebook' }) }}
    </p>
    <p
      v-html="$t('page.content.preview.sentence.facebook_requirements-html')"
    />
  </div>
</template>

<script lang="ts">
import { useProfile } from '@/composables/profile/useProfile'
import { computed, defineComponent, unref } from 'vue'
import CaptionPreview from './CaptionPreview.vue'

export default defineComponent({
  components: { CaptionPreview },
  props: {
    thumbnail: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isLoadingCreative: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { profile } = useProfile()
    
    const profilePick = computed(() => {
      return unref(profile).profilePick ? unref(profile).profilePick : ''
    })

    const profileName = computed(() => {
      return unref(profile).name
    })

    const hasContent = computed(() => {
      return !!props.content.trim().length
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    return {
      addDefaultSrc,
      profilePick,
      profileName,
      hasContent
    }
  }
})
</script>
