
import { useProfile } from '@/composables/profile/useProfile'
import { computed, defineComponent, unref } from 'vue'
import CaptionPreview from './CaptionPreview.vue'

export default defineComponent({
  components: { CaptionPreview },
  props: {
    thumbnail: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isLoadingCreative: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { profile } = useProfile()
    
    const profilePick = computed(() => {
      return unref(profile).profilePick ? unref(profile).profilePick : ''
    })

    const profileName = computed(() => {
      return unref(profile).name
    })

    const hasContent = computed(() => {
      return !!props.content.trim().length
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    return {
      addDefaultSrc,
      profilePick,
      profileName,
      hasContent
    }
  }
})
